import React,{useState,useRef} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import SelectField from './SelectField';

import { makeStyles } from "@material-ui/core/styles";

import MenuItem from "@material-ui/core/MenuItem";

import FormControl from "@material-ui/core/FormControl";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import AuthService from "../../services/auth.service";
import InputField from './InputField';
import { Button } from 'antd';



const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vusername = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must be between 3 and 20 characters.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

const AccountTypes = [
 
  {
    value: '111',
    label: 'Investor'
  },
  {
    value: '222',
    label: 'Dyanamic Discounting Customer'
  },
  {
    value: '333',
    label: 'Channel Partner'
  },
  {
    value: '444',
    label: 'Customer'
  },
  
 
];

const ConstitutionTypes = [
 
  {
    value: '111',
    label: 'Limited Liability Corporation'
  },
  {
    value: '222',
    label: 'Limited Liability Company'
  },
  {
    value: '333',
    label: 'Limited Liability Partnership'
  },
  {
    value: '444',
    label: 'Private Limited'
  },
  {
    value: '555',
    label: 'Public Limited'
  },
  {
    value: '666',
    label: 'Proprietorship'
  },
  {
    value: '777',
    label: 'Partnership'
  }
];








const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

export default function AddressForm(props) {
  const {
    formField: {
      companyName,
      mobile,
      Constitution,
      pan,
      gst,
      website,
      email,
      username,
      password,
      address,
      city,
      state,
      zipcode,
      country,
      agreeconditions
    }
  } = props;
  const conditions = <a href="conditions"> Terms and Conditions</a>
 
  const classes = useStyles();

  const registerBasic = () => {
    console.log(this.myRefs.username.value);

  }
 

  // const [entity, setEntity] = React.useState("");
  // const form = useRef();
  // const checkBtn = useRef();

  // const [username, setUsername] = useState("");
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  // const [successful, setSuccessful] = useState(false);
  // const [message, setMessage] = useState("");

  // const onChangeUsername = (e) => {
  //   const username = e.target.value;
  //   setUsername(username);
  // };

  // const onChangeEmail = (e) => {
  //   const email = e.target.value;
  //   setEmail(email);
  // };

  // const onChangePassword = (e) => {
  //   const password = e.target.value;
  //   setPassword(password);
  // };

  // const handleRegister = (e) => {
  //   e.preventDefault();

  //   setMessage("");
  //   setSuccessful(false);

  //   form.current.validateAll();

  //   if (checkBtn.current.context._errors.length === 0) {
  //     AuthService.register(username, email, password).then(
  //       (response) => {
  //         setMessage(response.data.message);
  //         setSuccessful(true);
  //       },
  //       (error) => {
  //         const resMessage =
  //           (error.response &&
  //             error.response.data &&
  //             error.response.data.message) ||
  //           error.message ||
  //           error.toString();

  //         setMessage(resMessage);
  //         setSuccessful(false);
  //       }
  //     );
  //   }
  // };

  // const handleChange = (event) => {
  //   setEntity(event.target.value);
  // };

 
 const usernameFieldRef = useRef();
 const passwordFieldRef = useRef();
 const emailFieldRef = useRef()
 const companyNameFieldRef = useRef()
 const  accountTypeFieldRef = useRef()
 const  mobileFieldRef = useRef()
 const  panFieldRef = useRef()
 const  gstFieldRef = useRef()
 const websiteFieldRef = useRef()
 const addressFieldRef = useRef()
 const cityFieldRef = useRef()
 const stateFieldRef = useRef()
 const zipcodeFieldRef  = useRef()
 const countryFieldRef = useRef()
 const  [selectedField,setSelectedField] = useState('')
 const  [selectedField2,setSelectedField2] = useState('')
const handleSelect = (event)=> {
  setSelectedField(event.target.value)
 
  
}
const handleAccountSelect = (event)=> {
  setSelectedField2(event.target.value)
 
  
}

const _handleSubmit = () => {
 
  const USERNAME  = usernameFieldRef.current.value;
  const EMAIL  = emailFieldRef.current.value;
  const COMPANYNAME  = companyNameFieldRef.current.value;
  const MOBILE  = mobileFieldRef.current.value;
  const PAN = panFieldRef.current.value;
  const GST  = gstFieldRef.current.value;
  const WEBSITE  = websiteFieldRef.current.value;
  const ADDRESS  = addressFieldRef.current.value;
  const CITY  = cityFieldRef.current.value;
  const STATE = stateFieldRef.current.value;
  
  const ZIPCODE = zipcodeFieldRef.current.value;
  const COUNTRY  = countryFieldRef.current.value;


  const PASSWORD = passwordFieldRef.current.value;




const CONSTITUTION  = selectedField
const ACCOUNT = selectedField2

console.log(USERNAME)
  console.log(EMAIL)
  console.log(PASSWORD)
  console.log(COMPANYNAME)
  console.log(Constitution )
  console.log(selectedField )
  console.log(selectedField2 )
  console.log(MOBILE)
  console.log(PAN)
  console.log(GST)
  console.log(WEBSITE)
  console.log(ADDRESS)
  console.log(CITY)
  console.log(STATE)
  console.log(ZIPCODE)
  console.log(COUNTRY)
//

AuthService.register(USERNAME, EMAIL, PASSWORD,COMPANYNAME,
  CONSTITUTION ,
  ACCOUNT,
  MOBILE,
  PAN,
  GST,
  WEBSITE,
  ADDRESS,
  CITY,
  STATE,
  ZIPCODE,
  COUNTRY,

  
  
  
 
  
  
  
  
  
  
  ).then(
  (response) => {
    console.log(response.data+'meow');
   
  },
  (error) => {
    const resMessage =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();

    console.log("abcd"+resMessage);
    
  }
);

}


//
  return (
    <React.Fragment>
          <Form>
      <Typography variant="h6" gutterBottom>
      Company Details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} >
          <InputField
          required
          inputRef= { companyNameFieldRef}
            name={companyName.name}
            label="Company Name"
            fullWidth
            
            
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            required
            id="mobile"
            inputRef= {mobileFieldRef}
            name={mobile.name}
            label="Mobile Number"
            fullWidth
            
           
          />
        </Grid>
        
         <FormControl className={classes.formControl} style={{minWidth:555}}>
         <SelectField
            name={Constitution.name}
            label="Company Constitution"
            data={ConstitutionTypes}
            fullWidth
            onClick={handleSelect}
            
           
            
          />
         
          
    
       
       
      </FormControl>
      <FormControl className={classes.formControl} style={{minWidth:555}}>
         <SelectField
            name={"account"}
            label="Account Type"
            data={AccountTypes }
            fullWidth
            onClick={handleAccountSelect}
            
           
            
          />
         
          
    
       
       
      </FormControl>
      
      <Grid item xs={6} >
      <InputField
            required id="cardName" name = {pan.name} label="PAN Number" fullWidth autoComplete="cc-name" inputRef={panFieldRef} />
        </Grid>
        <Grid item xs={6} >
        <InputField
            required id="cardName" name = {gst.name} label="GST Number" fullWidth autoComplete="cc-name" inputRef= {gstFieldRef}/>
        </Grid>
        <Grid item xs={12}>
          <InputField
            required
            id="website"
            name={website.name}
            inputRef= {websiteFieldRef}
            label="Company Website"
            fullWidth
            required
          
          />
          
        </Grid>





        <Grid item xs={12}>
        <InputField
            required
            id="email"
            inputRef= {emailFieldRef}
            name={email.name}
            label="Email"
            fullWidth
            // value={email}
            // onChange={onChangeEmail}
            // validations={[validEmail]}
            
          
          />
          
        </Grid>




        <Grid item xs={12}>
        <InputField
            required
            id="username"
            name={username.name}
            inputRef= {usernameFieldRef}
            label="Username"
            fullWidth
          
          />
        </Grid>
        <Grid item xs={12}>
        <InputField
            required
            id="password"
            inputRef={passwordFieldRef}
            name={password.name}
            inputRef= {passwordFieldRef}
            label="Password"
            fullWidth
            type="password"
            // value={password}
            // onChange={onChangePassword}
            // validations={[vpassword]}
          
          />
        </Grid>
        <Grid item xs={12}>
        <InputField
            required
            id="cpassword"
            name="confirm"
            label="Confirm Password"
            fullWidth
            type="password"
          
          />
        </Grid>
        



        <Grid item xs={12}>
        <InputField
            required
            id="address"
            name={address.name}
            inputRef= {addressFieldRef}
            label="Address line 1"
            fullWidth
            autoComplete="shipping address-line1"
          />
        </Grid>



        {/* <Grid item xs={12}>
          <TextField
            id="address2"
            name="address2"
            label="Address line 2"
            fullWidth
            autoComplete="shipping address-line2"
          />
        </Grid> */}
        <Grid item xs={12} sm={6}>
        <InputField
            required
            id="city"
            inputRef= {cityFieldRef}
            name={city.name}
            label="City"
            fullWidth
            autoComplete="shipping address-level2"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
        <InputField
            requiredid="state" name={state.name} label="State/Province/Region" fullWidth   inputRef= {stateFieldRef} />
        </Grid>
        <Grid item xs={12} sm={6}>
        <InputField
            required
            id="zip"
            name={zipcode.name}
            label="Zip / Postal code"
            fullWidth
            inputRef= {zipcodeFieldRef}
            autoComplete="shipping postal-code"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
        <InputField
            required
            id="country"
            name={country.name}
            label="Country"
            inputRef= {countryFieldRef} 
            fullWidth
            autoComplete="shipping country"
          />
        </Grid>
        
       
     
        <Grid item xs={10} sm={10}>
          <FormControlLabel
            control={<Checkbox color="secondary" name={agreeconditions.name} value="yes" required/>}
          /> <text> I Agree With The  {conditions} </text>
      
        </Grid>
        
      </Grid>
      {/* <CheckButton style={{ display: "none" }} ref={checkBtn} /> */}
      </Form>
      
      <Button onClick={_handleSubmit}> Submit Details</Button>
    </React.Fragment>
  );




 
}