import React from 'react';
import './contact.css'

import { Form, Input, Button,  } from 'antd';
const { TextArea } = Input;

function AppContact() {
  return (
    
    <section className="contact" id="contact">
       
        <div className="content">
            <div className="contactInfo">
                <h3 className='h3c'>Contact Info</h3>
                <div className="contactInfobx">
                    <div className="box">
                        
                        <div className="text">
                            <h3>Address</h3>
                            <p> Hemera Capital <br />Unit Number 4,5 & 6 <br /> 5th Floor, Tower A <br /> Emaar Digital Greens<br></br> Gurugram, Haryana</p>
                        </div>
                    </div>
                    <div className="box">
                       
                        <div className="text">
                            <h3>Phone </h3>
                            <p> Srijla Bagai<br />+919654322749 </p>
                        </div>
                    </div>
                    <div className="box">
                       
                        <div class="text">
                            <h3>E-mail </h3>
                            <p>admin@hemera.capital</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="formBx">
                <form action="mailto:admin@hemera.capital">
                    <h3>Send In Your Queries </h3>
                    <input type="text" name="" placeholder=" Full Name"></input>
                    <input type="email" name="" placeholder="Email"></input>
                    <textarea name="" id="" cols="15" placeholder="Your message " rows="5"></textarea>
                    <input type="submit" value="Send"></input>
                </form>
            </div>
        </div>
        </section>

     
  );
}

export default AppContact;