import React, { useState, useEffect } from "react";

import UserService from "../../services/user.service";
import Topbar from "./dashboard-components/topbar/topbar";
import Sidebar from "./dashboard-components/sidebar/sidebar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "../Dashboard/admin.css"

const AdminDashboard = () => {
  
return (
<Router>
      <Topbar />
      <div className="container">
        <Sidebar />
        <Switch>
          {/* <Route exact path="/">
            <Home />
          </Route>
          <Route path="/users">
            <UserList />
          </Route>
          <Route path="/user/:userId">
            <User />
          </Route>
          <Route path="/newUser">
            <NewUser />
          </Route>
          <Route path="/products">
            <ProductList />
          </Route>
          <Route path="/product/:productId">
            <Product />
          </Route>
          <Route path="/newproduct">
            <NewProduct />
          </Route> */}
        </Switch>
      </div>
    </Router>

















)
}
export default AdminDashboard;