












export const processObj = {
    id:'services',
    lightBg: false,
    greenBg: true,
    lightText: false,
    lightTextDesc: true,
    subline1:'Process:',
    topLine:'HOW IT WORKS',
    topLine2:'FACTORING/ SALE BILL DISCOUNTING',
    topLine3:'REVERSE FACTORING/ PURCHASE BILL DISCOUNTING',
    headline: `Collateral Free  Unsecured  
    Off Balance Sheet Working Capital`,
    description1: 'The supplier / buyer login our system and upload kyc documents',
    description2:'After login you will upload buyer / supplier details for approval and limit will be sanctioned for each buyer / supplier',
    description3:'The discounting process starts with the submission of digital form of invoices & grn to receiving payments.',
    description4:'The supplier raises the invoice and buyer verify and approve the same, the financier releases the payment of verified invoices within 2 days',
    description5:'->Supplier supplies the goods or services and raises the invoice',
    description6:'->Supplier login our platform and upload the invoice and GRN',
    description65:'->Buyer verify the invoice and approve the same',
    description121:'->Supplier verify the invoice and approve the same',


    description7:'->We inform the supplier rates and charges',
    description8:'->Supplier agrees to the charges',
    description9:'->Financier releases the payment to the supplier (upto 80-90% of the Invoice Amount)',
    description10:'In the reverse factoring process the buyer floats an invoice and the	Supplier verifies and approve the same for funding.																					',
    description11:'->Supplier supplies the goods or services and raises the invoice',

    description12:'->Buyer login our platform and upload the invoice and GRN',
    description13:'->Supplier verify the invoice and approve the same',
    description14:'->Buyer / supplier agrees the charges',
    description15:'->Financier releases the payment to the supplier(80-90% of the invoice value)',
    description16:'',

    
    buttonLabel: 'Get Started',
    imgStart: false,
    img: require('../../images/s.svg')?.default,
    alt: 'abcd',
    dark: true,
    primary: true,
    darkText: false
};


