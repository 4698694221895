import { makeStyles } from '@material-ui/core/styles';
import React from 'react'

import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import { Button } from 'antd';

import AuthService from '../../services/auth.service'

const useStyles = makeStyles((theme) => ({
    listItem: {
      padding: theme.spacing(1, 0),
    },
    total: {
      fontWeight: 700,
    },
    title: {
      marginTop: theme.spacing(2),
    },
  }));
function AddressDetails(props) {
    const classes = useStyles();
const { formValues } = props;
  const {  companyName,
    mobile,
    Constitution,
    pan,
    gst,
    website,
    email,
    username,
    password,
    address,
    city,
    state,
    zipcode,
    country,
    conditions} = formValues;
  const products = [
    { name: 'Company:',  price: `${companyName}` },
    { name: 'Constitution:',  price: `${Constitution}`},
    { name: 'Website:',  price: `${website}` },
    { name: 'PAN:',  price: `${pan}` },
    { name: 'GST:',  price: `${gst}`},
    
    

  
  ];
  
  const addresses = [`${address}`,`${city}`,`${state}`,`${zipcode}`,`${country}`];
const payments = [
  { name: 'Username: ', detail: `${username}` },
  { name: 'Mobile: ', detail: `${mobile}` },
  { name: 'Email:', detail: `${email}`},
  
];
const activatedMyUser = () => {
  const activated = 'yes'
  AuthService.activateUser(username,activated)
 
}
const registerUpdatedUser = (e) => {
  
    // e.preventDefault();

    

   
      AuthService.register(username, email, password,companyName,
        Constitution,
        mobile,
        
        pan,
        gst,
        website,
        address,
        city,
        state,
        zipcode,
        country,
        conditions
        
        
        
       
        
        
        
        
        
        
        ).then(
        (response) => {
          console.log(response.data+'meow');
         
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          console.log("abcd"+resMessage);
          
        }
      );

      }

  //


  return (
    <React.Fragment>
      
      <List disablePadding>
        {products.map((product) => (
          <ListItem className={classes.listItem} key={product.name}>
            <ListItemText primary={product.name} secondary={product.desc} />
            <Typography variant="body2">{product.price}</Typography>
          </ListItem>
        ))}
       
      </List>
      <Grid container spacing={5}>
        <Grid item xs={14} sm={5}>
          <Typography variant="h6" gutterBottom  className={classes.title}>
            Registerd Address:
          </Typography>
          
          <Typography gutterBottom>{addresses.join(' ')}</Typography>
        </Grid>
        <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            Account details
          </Typography>
          <Grid container>
            {payments.map((payment) => (
              <React.Fragment key={payment.name}>
                <Grid item xs={6}>
                  <Typography gutterBottom>{payment.name}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{payment.detail}</Typography>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Button onClick={registerUpdatedUser}> upload </Button>
      <Button onClick={activatedMyUser}> active </Button>
    </React.Fragment>
  );
 }
 export default AddressDetails;          