export default  {
    formId: 'checkoutForm',
    formField: {
        companyName: {
        name: 'companyName',
        label: 'companyName*',
        requiredErrorMsg: 'companyName is required'
      },
      mobile: {
        name: 'mobile',
        label: 'mobile*',
        requiredErrorMsg: 'mobile is required'
      },
      pan: {
        name: 'pan',
        label: 'pan*',
        requiredErrorMsg: 'pan is required'
      },
      Constitution: {
        name: 'Constitution',
        label: 'Constitution',
        requiredErrorMsg: 'Constitution is required'
        
      },
      website: {
        name: 'website',
        label: 'Website*',
        requiredErrorMsg: 'Website is required'
      },
      gst: {
        name: 'gst',
        label: 'gst*',
        requiredErrorMsg: 'gst is required'
      },
      email: {
        name: 'email',
        label: 'email*',
        requiredErrorMsg: 'email is required'
      },
      username: {
        name: 'username',
        label: 'Username*',
        requiredErrorMsg: 'username is required'
      },
      password: {
        name: 'password',
        label: 'Password*',
        requiredErrorMsg: 'username is required'
      },
      city: {
        name: 'city',
        label: 'City*',
        requiredErrorMsg: 'City is required'
      },
      state: {
        name: 'state',
        label: 'State/Province/Region'
      },
      zipcode: {
        name: 'zipcode',
        label: 'Zipcode*',
        requiredErrorMsg: 'Zipcode is required',
        invalidErrorMsg: 'Zipcode is not valid (e.g. 70000)'
      },
      country: {
        name: 'country',
        label: 'Country*',
        requiredErrorMsg: 'Country is required'
      },
      address: {
        name: 'address',
        label: 'Address*',
        requiredErrorMsg: 'Address is required'
      },
      agreeconditions: {
        name: 'useAddressForPaymentDetails',
        label: 'Use this address for payment details'
      },
    
    }
  };