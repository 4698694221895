 import React from 'react'
 import{FaBars} from 'react-icons/fa'
 import { animateScroll as scroll } from 'react-scroll'
 import {Nav,NavbarContainer,NavLogo,MobileIcon,NavMenu,NavItem,NavLinks,NavBtn,NavBtnLink} from './NavbarElements'
 const Navbar = ({toggle}) => {
     const toggleHome = () => {
         scroll.scrollToTop();
     }
     return (
         <>
         <Nav>
             <NavbarContainer>
                 <NavLogo to='/' onClick={toggleHome}>
                     Hemera Capital
                 </NavLogo>
                 <MobileIcon onClick={toggle}>
                     <FaBars />

                 </MobileIcon>
                 <NavMenu>
                     <NavItem>
                         <NavLinks to="about"
                         smooth={true} duration={500} spy={true} exact='true' > About</NavLinks>
                     </NavItem>
                     <NavItem>
                         <NavLinks to="services"
                         smooth={true} duration={500} spy={true} exact='true'> Services</NavLinks>
                     </NavItem>
                     <NavItem>
                         <NavLinks to="process"
                         smooth={true} duration={500} spy={true} exact='true'> Process </NavLinks>
                     </NavItem>
                     <NavItem>
                         <NavLinks to="advantages"
                         smooth={true} duration={500} spy={true} exact='true'> Advantages</NavLinks>
                     </NavItem>
                     <NavItem>
                         <NavLinks to="testimonial"
                         smooth={true} duration={500} spy={true} exact='true'> Testimonials</NavLinks>
                     </NavItem> 
                     <NavItem>
                         <NavLinks to="contact"
                         smooth={true} duration={500} spy={true} exact='true'> Contact Us</NavLinks>
                     </NavItem> 
                     
                 </NavMenu>
                 <NavBtn>
                     <NavBtnLink to="/signin"> Sign In</NavBtnLink>
                      
                 </NavBtn>
             </NavbarContainer>
         </Nav>
         </>
     )
 }
 
 export default Navbar
 