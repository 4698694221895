import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import { Formik, Form,useFormikContext } from 'formik';

import AddressForm from './AddressForm';
import PaymentForm from './PaymentForm';
import Review from './Review';
import Navbar from '../Navbar';



// import validationSchema from './FormModel/validationSchema';
import checkoutFormModel from './checkoutFormModel';
import formInitialValues from './formInitialValues';




const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));





const steps = ['Details', 'KYC', 'Review'];
const { formId, formField } = checkoutFormModel;


function _renderStepContent(step) {
  switch (step) {
    case 0:
      return <AddressForm formField={formField} />;
    case 1:
      return <PaymentForm  />;
    case 2:
      return <Review />;
    default:
      return <div>Not Found</div>;
  }
}

export default function CheckoutPage() {


  
   const classes = useStyles();



  // const {  companyName,
  //   mobile,
  //   Constitution,
  //   pan,
  //   gst,
  //   website,
  //   email,
  //   username,
  //   password,
  //   address,
  //   city,
  //   state,
  //   zipcode,
  //   country,
  //   conditions} = formValues;

  
  const [activeStep, setActiveStep] = useState(0);
  // const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  function _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function _submitForm(values, actions) {
    await _sleep(1000);
    alert(JSON.stringify(values, null, 2));
    actions.setSubmitting(false);

    setActiveStep(activeStep + 1);
  }

  function _handleSubmit(values, actions) {
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }
  function registerUser() {
    if(activeStep === 0){
      // console.log(username)
    }
  }


  return (
   
    <React.Fragment>
      <Navbar />
      <div>
        <br />
      </div>

      <CssBaseline />
      <AppBar position="absolute" color="default" className={classes.appBar}>
        
      </AppBar>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            Registration
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <>
                <Typography variant="h5" gutterBottom>
                 Signup Complete.
                </Typography>
                <Typography variant="subtitle1">
                  Thank You For Registering with us , Your Account Will Be  Shortly Activated After Verification Of KYC Documents.
                </Typography>
                </>
              
            ) : (
             
                <Formik
            initialValues={formInitialValues}
            // validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form id={formId}>
                {_renderStepContent(activeStep)}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={_handleBack} className={classes.button}>
                      Back
                    </Button>
                  )}
                  <Button
                   disabled={isSubmitting}
                   onClick={registerUser}
                   type="submit"
                    variant="contained"
                    color="primary"
                    // onClick={handleNext}
                    className={classes.button}
                  >
                    {isLastStep ? 'SignUp' : 'Next'}
                  </Button>
                  {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                </div>
                </Form>
                
            
            )}
            </Formik>)}
           
          </React.Fragment>
        </Paper>
       
      </main>
    </React.Fragment>
  );
}

