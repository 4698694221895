import React from 'react'
import { FaLinkedin,FaFacebook,FaTwitter } from 'react-icons/fa'
import { FooterContainer,FooterWrap,FooterLinksContainer,FooterLinkItems,FooterLinksWrapper,FooterLinkTitle,FooterLink,SocialMedia,SocialMediaWrap,SocialLogo,WebsiteRights,SocialIcons,SocialIconLink,UnderText} from './FooterElements'
const Footer = () => {
    return (
        <FooterContainer>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle> About Us</FooterLinkTitle>
                            
                                <FooterLink to="about"> About</FooterLink>
                                <FooterLink to={{pathname: "https://hemeraglobal.com"}} target="_blank"> Our Story </FooterLink>
                                <FooterLink to='/signin'> Manangement</FooterLink>
                   
                                <FooterLink to='/ signin'> Contact Us </FooterLink>
                            
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle> Why Us</FooterLinkTitle>
                            
                                <FooterLink to='/signin'> Services</FooterLink>
                                <FooterLink to='/signin'> Process</FooterLink>
                                <FooterLink to='/signin'> Advantages</FooterLink>
                                <FooterLink to='/signin'> Testimonials</FooterLink>
                                
                            
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle> Our Platform</FooterLinkTitle>
                            
                                <FooterLink to='/signin'> SignIn</FooterLink>
                                <FooterLink to='/signin'> SignUp</FooterLink>
                               
                                <FooterLink to='/signin'> Terms Of Use</FooterLink>
                                <FooterLink to='/ signin'> Privacy Policy </FooterLink>
                            
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                    
                </FooterLinksContainer>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to='/'>
                            Hemera Capital
                        </SocialLogo>
                        <WebsiteRights> Hemera Group  © 2021  All Rights Reserved </WebsiteRights>
                        <SocialIcons>
                            <SocialIconLink href="/" target="_blank" aria-label ="Facebook"> <FaFacebook /> </SocialIconLink>
                        
                       
            
                       

                      
                            <SocialIconLink href="/" target="_blank" aria-label ="LinkedIn"> <FaLinkedin /> </SocialIconLink>
                        

                       
                            <SocialIconLink href="/" target="_blank" aria-label ="Twitter"> <FaTwitter /> </SocialIconLink>
                        </SocialIcons>





                    </SocialMediaWrap>
                    <UnderText> HEMERA CAPITAL IS A GROUP COMPANY OF HEMERA GLOBAL HAVING PRESENCE IN SINGAPORE, HK, EUROPE, USA ETC </UnderText>
                        
                </SocialMedia>
            </FooterWrap>
            
        </FooterContainer>
    )
}

export default Footer
