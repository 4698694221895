import styled from "styled-components";
import {Link as LinkR} from 'react-router-dom'




export const InfoContainer = styled.div`
color: fff;
padding-top:60px;
/* background: ${({lightBg}) => (lightBg? '#00000' : '#fff')} ; */
/* background: linear-gradient(to right,white,#a8befa66) ; */
background: rgb(136, 226, 136);


@media screen and (max-width: 768px){
    padding: 100px 0 ;
}
`
export const InfoWrapper = styled.div`


display:flex;
z-index: 1;
height: 860px;
width: 100%;
max-width: 1100px;
margin-right: auto;
margin-left: auto;
//margin-bottom:-250px;
padding: 0 24px  ;
/* justify-content:  ; */

` 

export const InfoRow = styled.div`
 /* padding-top: 0em ; */

display: flex;

align-items : left;
padding-top:100px;




`
export const Column1 = styled.div`
margin-bottom: 15px;
/* padding: 0 15px; */

padding-bottom:20px


`


export const TextWrapper =  styled.div`

max-width: 840px;
padding-top: 0;
padding-bottom: 16px;
`
export const TopLine = styled.p`
//color: 	#8DBA6F;
//color: ${({greenBg}) => (greenBg ? 'rgb(19, 35, 92)' : '#8DBA6F')};
color: ${({greenBg}) => (greenBg ? 'rgb(19, 35, 92)' : '#8DBA6F')};
font-size: 24px;
line-height: 28px;
font-weight: 800;
letter-spacing: 4.2px;
text-transform: uppercase;
padding-top:8px;

`

export const TopLine2 = styled.p`
//color: 	#8DBA6F;
//color: ${({greenBg}) => (greenBg ? 'rgb(19, 35, 92)' : '#8DBA6F')};
color: ${({greenBg}) => (greenBg ? 'rgb(19, 35, 92)' : '#8DBA6F')};
font-size: 15px;
line-height: 16px;
font-weight: 400;
text-indent: 5px;

text-transform: uppercase;
padding-top:10px;
`


export const Heading = styled.h1`
margin-bottom: 24px;
margin-top: 50px;
font-size: 48px;
line-height: 1.1;
font-weight: 600;
color: ${({lightText}) => (lightText ? '#f7f8fa' : '010606')};

@media screen and (max-width: 480px){
    font-size: 32px;
}
`
export const Subtitle = styled.p`
max-width: 1100px;
/* margin-bottom: 35px; */
font-size: 14px;
line-height: 22px;
color:${({darkText}) => (darkText ? 'red' : '#010606bd')}; 
padding-top: 0.4em ;

`
export const BtnWrap = styled.div`
display: flex;
justify-content: flex-start ;
`
export const ImgWrap = styled.div`
max-width: 555px;
height: 100%;
`
export const Img = styled.img`
width:100%;
margin: 0 0 10px 0;
padding-right: 0;



`








