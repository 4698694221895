import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import { useFormikContext } from 'formik';
import AddressDetails from './AddressDetails'

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
}));

export default function Review() {
  const classes = useStyles();
  const { values: formValues } = useFormikContext();
 
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom >
      Confirm Details
      </Typography>
     
      <Grid container spacing={2}>
        <AddressDetails formValues={formValues} />
        
      </Grid>
    </React.Fragment>
  );
}