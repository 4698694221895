import styled from "styled-components";
import {Link as LinkR} from 'react-router-dom'




export const InfoContainer = styled.div`
color: fff;

background: ${({lightBg}) => (lightBg? '#f9f9f9' : 'rgb(147, 170, 249)')} ;


@media screen and (max-width: 768px){
    padding: 100px 0 ;
}
`
export const InfoWrapper = styled.div`

display:grid;
z-index: 1;
height: 860px;
width: 100%;
max-width: 1100px;
margin-right: auto;
margin-left: auto;
padding: 0 24px;
justify-content: center ;

` 

export const InfoRow = styled.div`
display: grid;
grid-auto-columns: minmax(auto, 1fr);
align-items : center;
grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'`: `'col1 col2'`)};

@media screen and (max-width: 768px) {
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'`: `'col1 col1' 'col2 col2'`)};
}

`
export const Column1 = styled.div`
margin-bottom: 15px;
padding: 0 15px;
grid-area: col1;

`
export const Column2 = styled.div`
margin-bottom: 15px;
padding: 0 15px;
grid-area: col2;

`

export const TextWrapper =  styled.div`

max-width: 1000px;
padding-top: 0;
padding-bottom: 60px;
`
export const TopLine = styled.p`
//color: 	#8DBA6F;
//color: ${({greenBg}) => (greenBg ? 'rgb(19, 35, 92)' : '#8DBA6F')};
color: ${({greenBg}) => (greenBg ? 'rgb(19, 35, 92)' : '#8DBA6F')};
font-size: 18px;
line-height: 16px;
font-weight: 600;
letter-spacing: 4.2px;
text-transform: uppercase;
margin-bottom:16px;
`
export const Heading = styled.h1`
margin-bottom: 24px;
font-size: 44px;
line-height: 1.1;
font-weight: 600;
color: ${({lightText}) => (lightText ? '#f7f8fa' : '010606')};

@media screen and (max-width: 480px){
    font-size: 32px;
}
`
export const Subtitle = styled.p`
max-width: 600px;
/* margin-bottom: 20px; */
font-size: 14px;
line-height: 24px;
color:${({darkText}) => (darkText ? 'red' : '#010606bd')};
text-align: left;


`
export const Subtitle2 = styled.p`
max-width: 600px;
/* margin-bottom: 35px; */
font-size: 14px;
line-height: 24px;
color:${({darkText}) => (darkText ? 'red' : '#010606bd')};
text-align: left;
white-space:normal;
`

export const BtnWrap = styled.div`
display: flex;
justify-content: flex-start ;
`
export const ImgWrap = styled.div`
max-width: 555px;
height: 100%;
`
export const Img = styled.img`
width:600px;
height: 400px;
/* width: 100%; */
margin: 0 0 120px 0;
padding-right: 0;
padding-top:25px;



`
export const Hlink = styled(LinkR)`
color: #203262;
justify-self: flex-start;
cursor: pointer;
font-size: 1.0rem;
display: flex;
align-items: center;


text-decoration: none;


`;