import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import React, { useState, useEffect } from "react";
import Home from './pages';
import SigninPage from './pages/SigninPage';
import Navbar from './components/Navbar';
import Checkout from './components/SignUp/Checkout';
import privacyPolicy from './pages/conditions';
import operations from './components/Dashboard/operations';
import conditions from './pages/conditions';
import Profile from './components/Dashboard/profile';
import AdminDashboard from './components/Dashboard/adminDashboard';
import UserDashboard from './components/Dashboard/userDashboard';
import AuthService from './services/auth.service';


function App() {
 
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      setCurrentUser(user);

      setShowAdminBoard(user.roles.includes("ROLE_ADMIN"));
    }
  }, []);

  const logOut = () => {
    AuthService.logout();
  };






  return (
    <Router> 
      
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path ="/signin" component={SigninPage} exact />
        <Route path ="/signup" component={Checkout} exact />
        <Route path ="/privacy-policy" component={privacyPolicy} exact />
        <Route path ="/operations" component={operations} exact />
        <Route path ="/conditions" component={conditions} exact />
        <Route path="/user" component={UserDashboard} />
        <Route exact path="/profile" component={Profile} />
          
          <Route path="/admin" component={AdminDashboard} />

      </Switch>
     
      
     </Router>
  );
}

export default App;
