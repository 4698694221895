import React , {useState} from 'react'
import AppContact from '../components/Contact/AppContact'
import Footer from '../components/Footer'
import HeroSection from '../components/HeroSection'
import InfoSection from '../components/InfoSection'
import { homeObjOne,homeObjTwo } from '../components/InfoSection/Data'
import Navbar from '../components/Navbar'
import Services from '../components/Services/Index'
import Sidebar from '../components/SIdebar'
import Reviews from '../components/Reviews/Index'
import Process from '../components/Process/Index'
import { processObj } from '../components/Process/Data'
import Checkout from '../components/SignUp/Checkout'
import Advantages from '../components/Advantages/Index'



const Home = () => {
    const [isOpen,setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }


    return (
        <>
        <Sidebar isOpen ={isOpen} toggle={toggle} />
        <Navbar toggle={toggle} />
        <HeroSection />
        <InfoSection {...homeObjOne} />
        
        <Services {...homeObjTwo} />
        <Process {...processObj} />
        <Advantages />
       
        <Reviews />
        <AppContact />
       

      

        <Footer />

        </>
    )
}

export default Home
