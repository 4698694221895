import React from 'react'
import { oneW,ServicesWrapper,ServicesCard,ServicesContainer,ServicesH1,ServicesH2,ServicesIcon,ServicesP } from './ProcessElements'
import Icon1 from '../../images/icon1.png'
import howit from  '../../images/howit.png'



 import { Button,Button1 } from '../ButtonElement'
 import { InfoContainer,InfoWrapper,InfoRow,Column1,Column2,TextWrapper,TopLine,Heading,Subtitle,BtnWrap,ImgWrap,Img,NavBtn,NavBtnLink,TopLine2 } from './ProcessElements'

 const Process = ({subline1,lightBg,greenBg,id,imgStart,topLine,topLine2,topLine3,lightText,headline,darkText,description1,description2,description3,description4,description5,description6,description7,description8,description9,description10,description11,description12,description13,description14,description15,description65,description121,buttonLabel,img,alt,primary,dark,dark2}) => {
     return (
   <>
        
              <InfoContainer lightBg={lightBg} id='process'>
    <div style={{paddingLeft:'140px',width:'100%',margin:'auto'}}>
        <img src={howit} style={{alignItems:"center"}} />
    </div>

{/* //                     <InfoWrapper>
//                         <InfoRow >
                          
//                             <TextWrapper>
//                                 <TopLine greenBg = {greenBg}  > {topLine} </TopLine>
                               
//                                 <Subtitle darkText={darkText}> {description1}</Subtitle>
//                                 <Subtitle darkText={darkText}> {description2}</Subtitle>
//                                 <Subtitle darkText={darkText}> {description3}</Subtitle>
//                                 <div>
//                                     <br />
//                                 </div>

//                                 <TopLine greenBg = {greenBg}  > {topLine2} </TopLine>
//                                 <Subtitle darkText={darkText}> {description4}</Subtitle>
//                                 <TopLine2 greenBg = {greenBg}  > {subline1} </TopLine2>
//                                 <Subtitle darkText={darkText}> {description5}</Subtitle>
//                                 <Subtitle darkText={darkText}> {description6}</Subtitle>
//                                 <Subtitle darkText={darkText}> {description65}</Subtitle>
//                                 <Subtitle darkText={darkText}> {description7}</Subtitle>
//                                 <Subtitle darkText={darkText}> {description8}</Subtitle>
//                                 <Subtitle darkText={darkText}> {description9}</Subtitle>
//                                 <div>
//                                     <br />
//                                 </div>
//                                 <TopLine greenBg = {greenBg}  > {topLine3} </TopLine>
//                                 <Subtitle darkText={darkText}> {description10}</Subtitle>
//                                 <TopLine2 greenBg = {greenBg}  > {subline1} </TopLine2>
//                                 <Subtitle darkText={darkText}> {description11}</Subtitle>
//                                 <Subtitle darkText={darkText}> {description12}</Subtitle>
//                                 <Subtitle darkText={darkText}> {description121}</Subtitle>
//                                 <Subtitle darkText={darkText}> {description13}</Subtitle>
//                                 <Subtitle darkText={darkText}> {description14}</Subtitle>
//                                 <Subtitle darkText={darkText}> {description15}</Subtitle>
                                

                               
//                             </TextWrapper>
                          
                           
//                         </InfoRow>
//                     </InfoWrapper> */}



               </InfoContainer>
               </>
               )
               }
            

       
       




export default Process;
