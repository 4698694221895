import React from 'react'

import { Button,Button1 } from '../ButtonElement'
import { InfoContainer,InfoWrapper,InfoRow,Column1,Column2,TextWrapper,TopLine,Heading,Subtitle,Subtitle2,BtnWrap,ImgWrap,Img,Hlink } from './InfoElements'
const InfoSection = ({lightBg,greenBg,id,imgStart,topLine,lightText,headline,darkText,description,description2,buttonLabel,img,alt,primary,dark,dark2,}) => {
    return (
        <>

        <InfoContainer lightBg={lightBg} id={id}>
            <InfoWrapper>
                <InfoRow imgStart={imgStart}>
                    <Column1>
                    <TextWrapper>
                        <TopLine greenBg = {greenBg}  > {topLine} </TopLine>
                        <Heading lightText = {lightText} >{headline}</Heading>
                        <Subtitle darkText={darkText}> {description}</Subtitle>
                        <Subtitle2 darkText={darkText}> {description2}</Subtitle2>
                    <Hlink to={{pathname: "https://hemeraglobal.com"}} target="_blank">Visit www.hemeraglobal.com </Hlink>
                       
                    </TextWrapper>
                    </Column1>
                    <Column2>
                    <ImgWrap>
                    <Img src={img} />

                    </ImgWrap>
                    </Column2>
                </InfoRow>
            </InfoWrapper>
        </InfoContainer>
        </>

    )
}

export default InfoSection
