import styled from "styled-components";







export const ServicesTop= styled.p`

color: #203262;
font-size: 18px;
line-height: 16px;
font-weight: 600;
letter-spacing: 4.2px;
text-transform: inherit;
margin-bottom:16px;
font-style:italic;
`


export const ServicesContainer = styled.div`
height: 800px; 
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background: #f9f9f9; 
/* background: azure ; */
/* background: rgb(147, 170, 249); */
padding-bottom: 5em;

@media screen and (max-width: 768px){
    /* height: 1100px; */
}

@media screen and (max-width: 480px){
    /* height: 1300px; */
}

`
export const ServicesWrapper = styled.div`
display: grid;
flex-direction: row;
/* max-width: 1000px; */
grid-template-columns: 1.8fr 1.8fr 1.8fr;
align-items:center;

grid-gap: 16px;
padding: 0 0px;

@media screen and (max-width: 1000px){
    grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 768px){
    grid-template-columns: 1fr;
    padding: 0 20px;
}



`
export const ServicesCard = styled.div`
/* background-color: #8DBA6F; */
 background-color: #aec1ff11; 
display: flex;
flex-direction: column;
align-items: center;
border-radius: 10px;
height: 280px;
width: 360px;
 padding: 10px; 
margin-bottom: 25px;

box-shadow: 2px 2px 2px 2px rgba(129, 129, 129, 0.466);

transition: all 0.2 ease-in-out;

&:hover{
    transform: scale(1.02);
    transition: all 0.2 ease-in-out;
    cursor: pointer;

}



`
export const ServicesIcon = styled.img`
height: 160px;
width: 160px;
margin-bottom: 2px;

`
export const ServicesH1 = styled.h1`
font-size: 2.5rem;
color: #000;
margin-bottom: 64px;

@media screen and (max-width: 400px){
    font-size: 2rem;
}

`
export const ServicesH2 = styled.h1`
font-size: 1.2rem;
margin-bottom: 5px;
white-space: nowrap;
`
export const ServicesP = styled.p`
font-size: 1rem;
text-align: center;





`