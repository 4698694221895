import checkoutFormModel from './checkoutFormModel';
const {
  formField: {
    companyName,
    mobile,
    Constitution,
    pan,
    gst,
    website,
    email,
    username,
    password,
    address,
    city,
    state,
    zipcode,
    country,
    agreeconditions
  }
} = checkoutFormModel;

export default {
  [companyName.name]: '',
  [mobile.name]: '',
  [Constitution.name]: '',
  [username.name]: '',
  [password.name]: '',
  [address.name]: '',
  [city.name]: '',
  [state.name]: '',
  [zipcode.name]: '',
  [country.name]: '',
  [agreeconditions.name]: false,
  [pan.name]: '',
  [gst.name]: '',
  [website.name]: '',
  [email.name]: ''
};