import styled from "styled-components";





export const InfoContainer = styled.div`
color: fff;

background: ${({lightBg}) => (lightBg? '#f9f9f9' : 'rgb(147, 170, 249)')} ;


@media screen and (max-width: 768px){
    padding: 100px 0 ;
}
`
export const InfoWrapper = styled.div`

display:grid;
z-index: 1;
height: 860px;
width: 100%;
max-width: 1100px;
margin-right: auto;
margin-left: auto;
//margin-bottom:-250px;
padding: 0 24px  ;
justify-content: center ;

` 

export const InfoRow = styled.div`
/* padding-top: 8em ; */
display: grid;
grid-auto-columns: minmax(auto, 1fr);
align-items : center;
grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'`: `'col1 col2'`)};

@media screen and (max-width: 768px) {
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'`: `'col1 col1' 'col2 col2'`)};
}

`
export const Column1 = styled.div`
margin-bottom: 15px;
padding: 0 15px;

grid-area: col1;

`
export const Column2 = styled.div`
margin-bottom: 15px;
padding: 0 15px;

grid-area: col2;

`

export const TextWrapper =  styled.div`

max-width: 540px;
padding-top: 0;
padding-bottom: 12px;
`
export const TopLine = styled.p`
//color: 	#8DBA6F;
//color: ${({greenBg}) => (greenBg ? 'rgb(19, 35, 92)' : '#8DBA6F')};
color: ${({greenBg}) => (greenBg ? 'rgb(19, 35, 92)' : '#8DBA6F')};
font-size: 18px;
line-height: 16px;
font-weight: 600;
letter-spacing: 4.2px;
text-transform: uppercase;

`
export const Heading = styled.h1`
margin-bottom: 24px;
margin-top: 50px;
font-size: 48px;
line-height: 1.1;
font-weight: 600;
color: ${({lightText}) => (lightText ? '#f7f8fa' : '010606')};

@media screen and (max-width: 480px){
    font-size: 32px;
}
`
export const Subtitle = styled.p`
max-width: 440px;
/* margin-bottom: 35px; */
font-size: 18px;
line-height: 30px;
color:${({darkText}) => (darkText ? 'red' : '#010606bd')}; 

`
export const BtnWrap = styled.div`
display: flex;
justify-content: flex-start ;
`
export const ImgWrap = styled.div`
max-width: 555px;
height: 100%;
`
export const Img = styled.img`
width:100%;
margin: 0 0 10px 0;
padding-right: 0;



`


export const ServicesContainer = styled.div`
/* height: 800px; */
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background: rgb(147, 170, 249);
/* background: rgb(147, 170, 249); */
padding-top :2em;

@media screen and (max-width: 768px){
    /* height: 1100px; */
}

@media screen and (max-width: 480px){
    /* height: 1300px; */
}

`
export const ServicesWrapper = styled.div`
display: grid;
flex-direction: row;
max-width: 3000px;
grid-template-columns: 1fr ;
align-items:center;
grid-gap: 16px;
padding: 0 60px;

@media screen and (max-width: 1000px){
    grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 768px){
    grid-template-columns: 1fr;
    padding: 0 20px;
}



`
export const ServicesCard = styled.div`
background-color:#fffff8;
display: flex;
flex-direction: row;
align-items: center;
border-radius: 10px;
/* max-height: 340px; */

width: 450px;
height: 50px;
padding: 10px;
margin-bottom:1px;
box-shadow: 0 5px 5px #a7befc60;

transition: all 0.2 ease-in-out;

&:hover{
    transform: scale(1.02);
    transition: all 0.2 ease-in-out;
    cursor: pointer;

}



`
export const ServicesIcon = styled.img`
height: 40px;
width: 80px;
margin-bottom: 1px;
padding-right:1em;
padding-left:1.5em;


`
export const oneW = styled.div`
padding: 100em;
display: flex;


`
export const ServicesH1 = styled.h1`
font-size: 18px;
color: #000;
margin-bottom: 64px;
letter-spacing: 4.2px;
line-height: 16px;
text-transform:uppercase;
font-weight: 600;

@media screen and (max-width: 400px){
    font-size: 2rem;
}

`
export const ServicesH2 = styled.h1`
font-size: 1.2rem;
margin-bottom: 5px;
white-space: nowrap;
text-align: left;
`
export const ServicesP = styled.p`
font-size: 1rem;
text-align: left;
white-space: nowrap;




`