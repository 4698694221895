import React from 'react'
import { oneW,ServicesWrapper,ServicesCard,ServicesContainer,ServicesH1,ServicesH2,ServicesIcon,ServicesP } from './ServicesElements'
import Icon1 from '../../images/icon1.png'
import coin from '../../images/coin.png'

import { Button,Button1 } from '../ButtonElement'
import { InfoContainer,InfoWrapper,InfoRow,Column1,Column2,TextWrapper,TopLine,Heading,Subtitle,BtnWrap,ImgWrap,Img } from './ServicesElements'

const Services = ({lightBg,greenBg,id,imgStart,topLine,lightText,headline,darkText,description,buttonLabel,img,alt,primary,dark,dark2}) => {
    return (
    <>
        
                <InfoContainer lightBg={lightBg} id='services'>
                    <InfoWrapper>
                        <InfoRow imgStart={imgStart}>
                            <Column1>
                            <TextWrapper>
                                <TopLine greenBg = {greenBg}  > {topLine} </TopLine>
                                <Heading lightText = {lightText} >{headline}</Heading>
                                <Subtitle darkText={darkText}> {description}</Subtitle>
                                {/* <BtnWrap>
                                    <Button1 to="home"
                                    smooth={true}
                                    duration={500}
                                    spy={true}
                                    exact="true"
                                    offset={-80}
                                    primary={primary ? 1: 0}
                                    dark = {dark ? 1: 0}
                                    dark2={dark2 ? 1: 0}
                                    
                                    
                                    > {buttonLabel}
                                    </Button1>
                                </BtnWrap> */}
                            </TextWrapper>
                        </Column1>
                            <Column2>
                            <ServicesContainer >
            <ServicesH1> We Discount Your</ServicesH1>
            <ServicesWrapper>
                <ServicesCard>
                
                    <ServicesIcon src={Icon1}/>
                    <oneW>
                    <ServicesH2>Sale Bills To Industries</ServicesH2>
                    <ServicesP> Post Shipment Financing</ServicesP>
                    </oneW>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon1}/>
                    <divW>
                    <ServicesH2>Purchase Bills From Vendors</ServicesH2>
                    <ServicesP> Pre Shipment Financing</ServicesP>
                    </divW>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon1}/>
                    <divW>
                    <ServicesH2>Dealer Finance</ServicesH2>
                    <ServicesP> For Distributers &amp; Dealers</ServicesP>
                    </divW>
                </ServicesCard>
           
            <ServicesCard>
                    <ServicesIcon src={Icon1}/>
                    <oneW> 
                    <ServicesH2>Dyanamic Discounting </ServicesH2>
                    <ServicesP> For Large Corporates</ServicesP>
                    </oneW>
                    </ServicesCard>
                    <ServicesCard>
                    <ServicesIcon src={Icon1}/>
                    <oneW> 
                    <ServicesH2>Service Bills Discounting </ServicesH2>
                    <ServicesP> For Corporates</ServicesP>
                    </oneW>
                    </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon1}/>
                    <divW>
                    <ServicesH2>Export Invoice Discounting </ServicesH2>
                    <ServicesP> Post Shipment Financing</ServicesP>
                    </divW>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon1}/>
                    <divW>
                    <ServicesH2>Import Invoice Discounting </ServicesH2>
                    <ServicesP> Pre Shipment Financing</ServicesP>
                    </divW>
                </ServicesCard>



            </ServicesWrapper>
        </ServicesContainer>
                            {/* <ImgWrap>
                            <Img src={img} />
        
                            </ImgWrap> */}
                            </Column2>
                        </InfoRow>
                    </InfoWrapper>
                </InfoContainer>
            

       
       
        </>
    )
}


export default Services;
