import React,{useState,useRef} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles, StylesProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Navbar from '../components/Navbar';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {Link as LinkR} from 'react-router-dom'
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import AuthService from '../services/auth.service'





const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom:theme.spacing(6)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  }
}));

export default function SignInPage(props) {
  
  const buyers = [
    {  label: 'HDFC',  },
    {  label: 'Axis Bank',  },
    {  label: 'ICICI BANK',  },
    {  label: 'KOTAK MAHINDRA',  },
    {  label: 'INDUSIND BANK',  },
  
  ]

  const classes = useStyles();
  const form = useRef();
  const checkBtn = useRef();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");



  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();

 


    // form.current.validateAll();

    
      AuthService.login(username, password).then(
        () => {
          props.history.push("/profile");
          window.location.reload();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

        
          console.log(resMessage);
        }
      );
    
  };










  return (
    <>
    
   <Navbar />
  
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Form>
        <form className={classes.form} onSubmit={handleLogin}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Username"
            name="username"
            value={username}
            onChange={onChangeUsername}
          
            
           
            
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Password"
            type="password"
            id="password"
            name="password"
            value={password}
            onChangeCapture={onChangePassword}
            onChange={onChangePassword}
           
            
          />
          
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleLogin}
            
          >
            Sign In
          </Button>
          
          <Grid container>
            <Grid item xs>
              <LinkR to='/' variant="body2">
                Forgot password?
              </LinkR>
            </Grid>
            <Grid item>
              <LinkR to="/signup" variant="body2">
                {"New User ? Sign Up"}
              </LinkR>
            </Grid>
          </Grid>
        </form>
        {console.log(password)}
       
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
       
      
      
      </div>





      <div>
        
        <p  style={{alignItems : "justify",fontFamily:"montserart" ,fontSize:'18px',marginTop:'-20px'}}> For suppliers looking for immediate funding for your supply to largest buyers, we have a long list of pre- approved buyers and you don’t need any pre-sanctions to discount your sale bills to these pre-approved buyers.</p>
        
        <p  style={{alignItems : "justify",fontFamily:"Encode Sans Expanded" ,fontSize:'18px'}}> Type in buyer name to see if they are pre-approved</p>
        <br />



        <center> 
        <Autocomplete
      id="country-select-demo"
      style={{ width: 400 }}
      options={buyers}
      classes={{
        option: classes.option,
      }}
      autoHighlight
      getOptionLabel={(option) => option.label}
      renderOption={(option) => (
        <React.Fragment>
          {option.label} 
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search Buyer"
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
</center>
<br />
<br />
      </div>
   
    </Container>

    </>
  );
 
 
}
