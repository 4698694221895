import axios from "axios";

// const API_URL = "http://localhost:8080/api/auth/";
const API_URL = "https://hemera-user-backend.herokuapp.com/api/auth/";

const register = (username, email, password,companyname,
  constitution,
  account,
  mobile,
  pan,
  gst,
  website,
  address,
  city,
  state,
  postal,
  country) => {
  return axios.post(API_URL + "signup", {
    username,
    email,
    password,
    companyname,
    constitution,
    account,
    mobile,
    pan,
    gst,
    website,
    address,
    city,
    state,
    postal,
    country,

   
    // //






  });
};

const activateUser = (username, activated) => {
  return axios.post(API_URL + "activate", {
    username,
    activated,

  });
};







const login = (username, password) => {
  return axios
    .post(API_URL + "signin", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

export default {
  register,
  login,
  activateUser,
  logout,
  getCurrentUser
};