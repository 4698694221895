import { makeStyles } from '@material-ui/core/styles';
import React,{useState} from 'react'

import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';

import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { Input } from '@material-ui/core';
import AddressForm from './AddressForm';
import { DropzoneDialog } from 'material-ui-dropzone';
import Button from '@material-ui/core/Button';
import { render } from '@testing-library/react';
import { LaptopWindowsTwoTone } from '@material-ui/icons';



















const useStyles = makeStyles((theme) => ({
    listItem: {
      padding: theme.spacing(1, 0),
    },
    total: {
      fontWeight: 700,
    },
    title: {
      marginTop: theme.spacing(2),
    },
  }));
export default function Kyc(props) {
    const { formValues } = props;
    const {  companyName,
      mobile,
      Constitution,
      pan,
      gst,
      website,
      email,
      username,
      password,
      address,
      city,
      state,
      zipcode,
      country,
      conditions} = formValues;

    const [open, setOpen] = React.useState(false);
    const [panText, setPanText] = React.useState("Click To Add File");
    const [panFile, setPanFile] = React.useState(null);
    const [gstFile, setGstFile] = React.useState(null);
    const [aadharpassFile, setAadharpassFile] = React.useState(null);
    const [ memorandumFile, setMemorandumFile] = React.useState(null);
    const [registrationFile, setRegistrationFile] = React.useState(null);
    const [directorpanFile, setDirectorpanFile] = React.useState(null);
    const [directorlistFile, setDirectorlistFile] = React.useState(null);
    const [addressproofFile, setAddressproofFile] = React.useState(null);
    const [shareholdingFile, setShareholdingFile] = React.useState(null);
    const [cancelledchequeFile, setCancelledchequeFile] = React.useState(null);
    const [partnershipdeedurlFile, setPartnershipdeedurlFile] = React.useState(null);
    const [partnerspanurlFile, setPartnerspanurlFile]= React.useState(null);
    const [partnersPanFile, setPartnersPanFile]= React.useState(null);


   


    //  "partnerspanurl":"none",







    
    const [fileText, setFileText] = React.useState("Click To Add File");
    const [fileName, setFileName] = React.useState("");

    const handlepan = (event) => {
        
        setPanText("Click To Change File")
        console.log(event);
        console.log("hello");
    
      };

    const handlefile = (event) => {
       
        setPanText("Click To Change File")
        console.log(event);
        console.log("hello");
    
      };

      const  onPanUpload =  async() => {
          if(!panFile){
              alert("select file")
          }
        
          const formdata = new FormData();
          formdata.append('username',`${username}`);
          formdata.append('croppedImage',panFile);
          formdata.append('filetype','panurl');
        const res =   await fetch("https://hemera-kyc-api.herokuapp.com/api/users/setProfilePic", {
              method:'POST',
              body:formdata,
          })
      }

      const  onGstUpload =  async() => {
        if(!gstFile){
            alert("select file")
        }
      
        const formdata = new FormData();
        formdata.append('username',`${username}`);
        formdata.append('croppedImage',gstFile);
        formdata.append('filetype','gsturl');
        const res =   await fetch("https://hemera-kyc-api.herokuapp.com/api/users/setProfilePic", {
            method:'POST',
            body:formdata,
        })
    }

    const  onAdhaarpassUpload =  async() => {
        if(!aadharpassFile){
            alert("select file")
        }
      
        const formdata = new FormData();
        formdata.append('username',`${username}`);
        formdata.append('croppedImage',aadharpassFile);
        formdata.append('filetype','aadharpassurl');
        const res =   await fetch("https://hemera-kyc-api.herokuapp.com/api/users/setProfilePic", {
            method:'POST',
            body:formdata,
        })
    }
    const  onMemorandumUpload =  async() => {
        if(!memorandumFile){
            alert("select file")
        }
      
        const formdata = new FormData();
        formdata.append('username',`${username}`);
        formdata.append('croppedImage',memorandumFile);
        formdata.append('filetype','memorandumurl');
        const res =   await fetch("https://hemera-kyc-api.herokuapp.com/api/users/setProfilePic", {
            method:'POST',
            body:formdata,
        })
    }

    const  onRegistrationUpload =  async() => {
        if(!registrationFile){
            alert("select file")
        }
      
        const formdata = new FormData();
        formdata.append('username',`${username}`);
        formdata.append('croppedImage',registrationFile);
        formdata.append('filetype','registrationurl');
        const res =   await fetch("https://hemera-kyc-api.herokuapp.com/api/users/setProfilePic", {
            method:'POST',
            body:formdata,
        })
    }
    const  onDirectorpanUpload =  async() => {
        if(!directorpanFile){
            alert("select file")
        }
      
        const formdata = new FormData();
        formdata.append('username',`${username}`);
        formdata.append('croppedImage',directorpanFile);
        formdata.append('filetype','directorpanurl');
        const res =   await fetch("https://hemera-kyc-api.herokuapp.com/api/users/setProfilePic", {
            method:'POST',
            body:formdata,
        })
    }
    const  onDirectorlistUpload =  async() => {
        if(!directorlistFile){
            alert("select file")
        }
      
        const formdata = new FormData();
        formdata.append('username',`${username}`);
        formdata.append('croppedImage',directorlistFile);
        formdata.append('filetype','directorlisturl');
        const res =   await fetch("https://hemera-kyc-api.herokuapp.com/api/users/setProfilePic", {
            method:'POST',
            body:formdata,
        })
    }
    const  onAddressProofUpload =  async() => {
        if(!addressproofFile){
            alert("select file")
        }
      
        const formdata = new FormData();
        formdata.append('username',`${username}`);
        formdata.append('croppedImage',addressproofFile);
        formdata.append('filetype','addressproofurl');
        const res =   await fetch("https://hemera-kyc-api.herokuapp.com/api/users/setProfilePic", {
            method:'POST',
            body:formdata,
        })
    }
    const  onShareholdingUpload =  async() => {
        if(!shareholdingFile){
            alert("select file")
        }
      
        const formdata = new FormData();
        formdata.append('username',`${username}`);
        formdata.append('croppedImage',shareholdingFile);
        formdata.append('filetype','shareholdingurl');
        const res =   await fetch("https://hemera-kyc-api.herokuapp.com/api/users/setProfilePic", {
            method:'POST',
            body:formdata,
        })
    }
    const  onCancelledchequeUpload =  async() => {
        if(!cancelledchequeFile){
            alert("select file")
        }
      
        const formdata = new FormData();
        formdata.append('username',`${username}`);
        formdata.append('croppedImage',cancelledchequeFile);
        formdata.append('filetype','cancelledchequeurl');
        const res =   await fetch("https://hemera-kyc-api.herokuapp.com/api/users/setProfilePic", {
            method:'POST',
            body:formdata,
        })
    }
    const  onPartnershipdeedUpload =  async() => {
        if(!partnershipdeedurlFile){
            alert("select file")
        }
      
        const formdata = new FormData();
        formdata.append('username',`${username}`);
        formdata.append('croppedImage',partnershipdeedurlFile);
        formdata.append('filetype','partnershipdeedurl');
        const res =   await fetch("https://hemera-kyc-api.herokuapp.com/api/users/setProfilePic", {
            method:'POST',
            body:formdata,
        })
    }
    


    const  onPartnerspanUpload =  async() => {
        if(!partnersPanFile){
            alert("select file")
        }
      
        const formdata = new FormData();
        formdata.append('username',`${username}`);
        formdata.append('croppedImage',partnersPanFile);
        formdata.append('filetype','partnerspanurl');
        const res =   await fetch("https://hemera-kyc-api.herokuapp.com/api/users/setProfilePic", {
            method:'POST',
            body:formdata,
        })
    }


    

   




      


      // current 
      const onSelectPan = (event) => {
        //   const reader  = new FileReader()
        //   reader.readAsDataURL(event.target.files[0])
        //   reader.addEventListener('load',() => {
        //       setPanFile(reader.result);
             
        //   })
        setPanFile(event.target.files[0])
      }

      const onSelectGst = (event) => {
        //   const reader  = new FileReader()
        //   reader.readAsDataURL(event.target.files[0])
        //   reader.addEventListener('load',() => {
        //       setPanFile(reader.result);
             
        //   })
        setGstFile(event.target.files[0])
      }
      const onSelectAdhaarpass = (event) => {
        
        setAadharpassFile(event.target.files[0])
      }
      const onSelectMemorandum = (event) => {
        
        setMemorandumFile(event.target.files[0])
      }

      const onSelectRegistration= (event) => {
        
        setRegistrationFile(event.target.files[0])
      }

      const onSelectDirectorpan= (event) => {
        
        setDirectorpanFile(event.target.files[0])
      }

    
      const   onSelectDirectorlist = (event) => {
        
        setDirectorlistFile(event.target.files[0])
      }

      const   onSelectShareholdingFile = (event) => {
        
        setShareholdingFile(event.target.files[0])
      }


      const   onSelectCancelledchequeFile = (event) => {
        
        setCancelledchequeFile(event.target.files[0])
      }


      const   onSelectAddressproofFile = (event) => {
        
        setAddressproofFile(event.target.files[0])
      }
//

      const   onSelectPartnershipdeedurlFile = (event) => {
        
        setPartnershipdeedurlFile(event.target.files[0])
      }
      const   onSelectPartnersPanFile = (event) => {
        
        setPartnersPanFile(event.target.files[0])
      }


  




    
    const classes = useStyles();
// const { formValues } = props;
//   const {  companyName,
//     mobile,
//     Constitution,
//     pan,
//     gst,
//     website,
//     email,
//     username,
//     password,
//     address,
//     city,
//     state,
//     zipcode,
//     country,
//     conditions} = formValues;
//   const products = [
//     { name: 'Company:',  price: `${companyName}` },
//     { name: 'Constitution:',  price: `${Constitution}`},
//     { name: 'Website:',  price: `${website}` },
//     { name: 'PAN:',  price: `${pan}` },
//     { name: 'GST:',  price: `${gst}`},
    
    

  
//   ];

//   const addresses = [`${address}`,`${city}`,`${state}`,`${zipcode}`,`${country}`];
// const payments = [
//   { name: 'Username: ', detail: `${username}` },
//   { name: 'Mobile: ', detail: `${mobile}` },
//   { name: 'Email:', detail: `${email}`},
  
// ];
// const onUpload

if (Constitution==='Limited Liability Corporation' || Constitution==='Limited Liability Company'|| Constitution==='Limited Liability Partnership' || Constitution==='Private Limited' || Constitution==='Public Limited' )
{
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
       KYC Details
      </Typography>
      <Grid container spacing={4}>
  
     
        <Grid item xs={12} md={6}>
          <label> Upload Company PAN Card 
         
          </label> 
          <Button variant="contained" color="primary" onClick={onPanUpload}>
    upload
  </Button>
  
  <input type="file" onChange={onSelectPan} />




        </Grid>
        <Grid item xs={12} md={6}>
        <label> Upload GST Certificate {fileName}
         
         </label> 

         <Button variant="contained" color="primary" onClick={onGstUpload}>
   Upload
 </Button>
 <input type="file" onChange={onSelectGst} />
 {/* <DropzoneDialog
   
   cancelButtonText={"cancel"}
   submitButtonText={"submit"}
   maxFileSize={5000000}
   open={open}
   onClose={() => setOpen(false)}
   onSave={(files) => {

    
     setOpen(false);
   }}
   showPreviews={true}
   showFileNamesInPreview={true}
   showFileNames={true}
   previewText={true}
   
 /> */}
        </Grid>
        
        <Grid item xs={0} md={6}>
        <label> Upload Aadhar Card / Passport Of Directors
         
         </label> 
         <Button variant="contained" color="primary" onClick={onAdhaarpassUpload}>
   Upload
 </Button>



 <input type="file" onChange={onSelectAdhaarpass} />
 {/* <DropzoneDialog
   
   cancelButtonText={"cancel"}
   submitButtonText={"submit"}
   maxFileSize={5000000}
   open={open}
   onClose={() => setOpen(false)}
   onSave={(files) => {

    
     setOpen(false);
   }}
   showPreviews={true}
   showFileNamesInPreview={true}
   showFileNames={true}
   previewText={true}
   
 /> */}
        </Grid>
        <Grid item xs={0} md={6}>
        <label> Upload the Memorandum and  AOA of company{fileName}
         
         </label> 
         <Button variant="contained" color="primary" onClick={onMemorandumUpload}>
  Upload
 </Button>
 <input type="file" onChange={onSelectMemorandum} />
 {/* <DropzoneDialog
   
   cancelButtonText={"cancel"}
   submitButtonText={"submit"}
   maxFileSize={5000000}
   open={open}
   onClose={() => setOpen(false)}
   onSave={(files) => {

    
     setOpen(false);
   }}
   showPreviews={true}
   showFileNamesInPreview={true}
   showFileNames={true}
   previewText={true}
   
 /> */}
        </Grid>
        <Grid item xs={0} md={6}>
        <label> Upload Company Registration Certificate{fileName}
         
         </label> 
         <Button variant="contained" color="primary" onClick={onRegistrationUpload}>
Upload
 </Button>
 <input type="file" onChange={onSelectRegistration} />
 {/* <DropzoneDialog
   
   cancelButtonText={"cancel"}
   submitButtonText={"submit"}
   maxFileSize={5000000}
   open={open}
   onClose={() => setOpen(false)}
   onSave={(files) => {

    
     setOpen(false);
   }}
   showPreviews={true}
   showFileNamesInPreview={true}
   showFileNames={true}
   previewText={true}
   
 /> */}
        </Grid>
        <Grid item xs={0} md={6}>
        <label> Upload  PAN card of company directors {fileName}
         
         </label> 
         <Button variant="contained" color="primary" onClick={onDirectorpanUpload}>
   Upload
 </Button>
 <input type="file" onChange={onSelectDirectorpan} />
 
 {/* <DropzoneDialog
   
   cancelButtonText={"cancel"}
   submitButtonText={"submit"}
   maxFileSize={5000000}
   open={open}
   onClose={() => setOpen(false)}
   onSave={(files) => {

    
     setOpen(false);
   }}
   showPreviews={true}
   showFileNamesInPreview={true}
   showFileNames={true}
   previewText={true}
   
 /> */}
        </Grid>
        <Grid item xs={0} md={6}>
        <label> Upload list of directors 
         
         </label> 
         <Button variant="contained" color="primary" onClick={onDirectorlistUpload}>
   Upload
 </Button>
 <input type="file" onChange={onSelectDirectorlist} />
 {/* <DropzoneDialog
   
   cancelButtonText={"cancel"}
   submitButtonText={"submit"}
   maxFileSize={5000000}
   open={open}
   onClose={() => setOpen(false)}
   onSave={(files) => {

    
     setOpen(false);
   }}
   showPreviews={true}
   showFileNamesInPreview={true}
   showFileNames={true}
   previewText={true}
   
 /> */}
        </Grid>
        <Grid item xs={0} md={6}>
        <label> Upload company address proof  {fileName}
         
          </label> 
          <Button variant="contained" color="primary" onClick={onAddressProofUpload}>
   Upload
  </Button>
  <input type="file" onChange={onSelectAddressproofFile} />
  {/* <DropzoneDialog
    
    cancelButtonText={"cancel"}
    submitButtonText={"submit"}
    maxFileSize={5000000}
    open={open}
    onClose={() => setOpen(false)}
    onSave={(files) => {

     
      setOpen(false);
    }}
    showPreviews={true}
    showFileNamesInPreview={true}
    showFileNames={true}
    previewText={true}
    
  /> */}
        </Grid>
        <Grid item xs={0} md={6}>
        <label> Upload shareholding pattern  {fileName}
         
          </label> 
          <Button variant="contained" color="primary" onClick={onShareholdingUpload}>
    Upload
  </Button>
  <input type="file" onChange={onSelectShareholdingFile} />
  
  {/* <DropzoneDialog
    
    cancelButtonText={"cancel"}
    submitButtonText={"submit"}
    maxFileSize={5000000}
    open={open}
    onClose={() => setOpen(false)}
    onSave={(files) => {

     
      setOpen(false);
    }}
    showPreviews={true}
    showFileNamesInPreview={true}
    showFileNames={true}
    previewText={true}
    
  /> */}
        </Grid>
        
        <Grid item xs={0} md={6}>
        <label> Upload cancelled copy of cheque 
         
         </label> 
         <Button variant="contained" color="primary" onClick={onCancelledchequeUpload}>
   Upload
   
 </Button>

 <input type="file" onChange={onSelectCancelledchequeFile} />
 {/* <DropzoneDialog
   
   cancelButtonText={"cancel"}
   submitButtonText={"submit"}
   maxFileSize={5000000}
   open={open}
   onClose={() => setOpen(false)}
   onSave={(files) => {

    
     setOpen(false);
   }}
   showPreviews={true}
   showFileNamesInPreview={true}
   showFileNames={true}
   previewText={true}
   
 /> */}
        </Grid>
       
      </Grid>
      <br />
     
    </React.Fragment>

  );
}





if (Constitution==='Partnership') {

    return (
        <React.Fragment>
          <Typography variant="h6" gutterBottom>
           KYC Details
          </Typography>
          <Grid container spacing={4}>
      
         
          <Grid item xs={12} md={6}>
          <label> Upload Company PAN Card
         
          </label> 
          <Button variant="contained" color="primary" onClick={onPanUpload}>
    Upload
  </Button>
  <input type="file" onChange={onSelectPan} />

  {/* <DropzoneDialog
    
    cancelButtonText={"cancel"}
    submitButtonText={"submit"}
    maxFileSize={5000000}
    open={open}
    onClose={() => setOpen(false)}
    onSave={(files) => {

     
      setOpen(false);
    }}
    showPreviews={true}
    showFileNamesInPreview={true}
    showFileNames={true}
    previewText={true}
    
  /> */}
        </Grid>
        <Grid item xs={12} md={6}>
        <label> Upload GST Certificate {fileName}
         
         </label> 
         <Button variant="contained" color="primary" onClick={onGstUpload}>
 Upload
 </Button>
 <input type="file" onChange={onSelectGst} />
 {/* <DropzoneDialog
   
   cancelButtonText={"cancel"}
   submitButtonText={"submit"}
   maxFileSize={5000000}
   open={open}
   onClose={() => setOpen(false)}
   onSave={(files) => {

    
     setOpen(false);
   }}
   showPreviews={true}
   showFileNamesInPreview={true}
   showFileNames={true}
   previewText={true}
   
 /> */}
        </Grid>
            
        <Grid item xs={0} md={6}>
        <label> Upload Aadhar Card / Passport Of Partners
         
         </label> 
         <Button variant="contained" color="primary" onClick={onAdhaarpassUpload}>
   Upload
 </Button>
 <input type="file" onChange={onSelectAdhaarpass} />

        </Grid>
        
            <Grid item xs={0} md={6}>
        <label> Upload Partnership Deed {fileName}
         
         </label> 
         <Button variant="contained" color="primary" onClick={onPartnershipdeedUpload}>
 Upload
 </Button>
 <input type="file" onChange={onSelectPartnershipdeedurlFile} />

        </Grid>
        <Grid item xs={0} md={6}>
        <label> Upload  PAN card of company directors        
         
         </label> 
         <Button variant="contained" color="primary" onClick={onPartnerspanUpload}>
Upload
 </Button>
 <input type="file" onChange={onSelectPartnersPanFile } />
 


        </Grid>
        <Grid item xs={0} md={6}>

        <label> Upload  Company Address Proof       
         
         </label> 
         <Button variant="contained" color="primary" onClick={onAddressProofUpload}>
Upload
 </Button>
 <input type="file" onChange={onSelectAddressproofFile } />

        </Grid>
            
           
           
           
          
            <Grid item xs={0} md={6}>
        <label> Upload cancelled copy of cheque
         </label> 
         <Button variant="contained" color="primary" onClick={onCancelledchequeUpload}>
       Upload
 </Button>

 <input type="file" onChange={onSelectCancelledchequeFile } />


        </Grid>
          </Grid>
          <br />
         
        </React.Fragment>
    
      );


}
if (Constitution==='Proprietorship') {

    return (
        <React.Fragment>
          <Typography variant="h6" gutterBottom>
           KYC Details
          </Typography>
          <Grid container spacing={4}>
      
         
          <Grid item xs={12} md={6}>
          <label> Upload Company PAN Card 
         
          </label> 
          <Button variant="contained" color="primary" onClick={onPanUpload}>
   Upload
  </Button>
  <input type="file" onChange={onSelectPan} />

  {/* <DropzoneDialog
    
    cancelButtonText={"cancel"}
    submitButtonText={"submit"}
    maxFileSize={5000000}
    open={open}
    onClose={() => setOpen(false)}
    onSave={(files) => {

     
      setOpen(false);
    }}
    showPreviews={true}
    showFileNamesInPreview={true}
    showFileNames={true}
    previewText={true}
    
  /> */}
        </Grid>
        <Grid item xs={12} md={6}>
        <label> Upload GST Certificate 
         </label> 
         <Button variant="contained" color="primary" onClick={onGstUpload}>
  Upload
 </Button>
 <input type="file" onChange={onSelectGst} />
 {/* <DropzoneDialog
   
   cancelButtonText={"cancel"}
   submitButtonText={"submit"}
   maxFileSize={5000000}
   open={open}
   onClose={() => setOpen(false)}
   onSave={(files) => {


    
     setOpen(false);
   }}
   showPreviews={true}
   showFileNamesInPreview={true}
   showFileNames={true}
   previewText={true}
   
 /> */}
        </Grid>
            
        <Grid item xs={0} md={6}>
        <label> Upload Aadhar Card / Passport Of Proprietor
         
         </label> 
         <Button variant="contained" color="primary" onClick={onAdhaarpassUpload}>
Upload
 </Button>
 <input type="file" onChange={onSelectAdhaarpass} />



        </Grid>
           
       
        <Grid item xs={0} md={6}>
        <label> Upload  Company Address Proof       
         
         </label> 
         <Button variant="contained" color="primary" onClick={onAddressProofUpload}>
Upload
 </Button>
 <input type="file" onChange={onSelectAddressproofFile} />
 {/* <DropzoneDialog
   
   cancelButtonText={"cancel"}
   submitButtonText={"submit"}
   maxFileSize={5000000}
   open={open}
   onClose={() => setOpen(false)}
   onSave={(files) => {


    
     setOpen(false);
   }}
   showPreviews={true}
   showFileNamesInPreview={true}
   showFileNames={true}
   previewText={true}
   
 /> */}
        </Grid>
            
           
           
           
          
            <Grid item xs={0} md={6}>
        <label> Upload cancelled copy of cheque 
         
         </label> 
         <Button variant="contained" color="primary" onClick={onCancelledchequeUpload}>
   Upload
 </Button>
 <input type="file" onChange={onSelectCancelledchequeFile} />

        </Grid>
          </Grid>
          <br />
         
        </React.Fragment>
    
      );


}
 }
     