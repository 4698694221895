







const a = <h1> hello</h1>


export const homeObjOne = {
    id:'about',
    lightBg: true,
    lightText: false,
    lightTextDesc: true,
    topLine:'About Hemera Capital',
    headline: 'Your One Stop Solution To Discounting Of Invoices, Globally',
    description: `Hemera Capital is a digital market place to sell the receivables to NBFCs, Banks and other Financial Institutions to facilitate the discounting of Invoices and Bills of Exchange globally.  We are into invoice discounting , supply chain financing / channel financing,vendor financing, export-import financing. `,
    description2:`Hemera Capital is a group company of Hemera Global having presence in Hong Kong,Singapore,US,Europe,etc`,
    buttonLabel: 'Get Started',
    hlink:'for more info visit hemeraglobal.com',
    imgStart: false,
    img: require('../../images/f3.webp')?.default,
    alt: 'abcd',
    dark: true,
    primary: true,
    darkText: false
};

export const homeObjTwo = {
    id:'services',
    lightBg: false,
    greenBg: true,
    lightText: false,
    lightTextDesc: true,
    topLine:'Services Offered',
    headline: `Collateral Free, Unsecured,Off Balance Sheet Working Capital`,
    description: 'Our Factoring Facility Helps You to Focus on What You Do Best (Your Own Business) and Let Us Take Care of What We Do Best - Finance, Collections & Credit Protection. I.e. One Stop Solution for All Your Problems on Receivables. ',
    buttonLabel: 'Learn How',
    imgStart: true,
    img: require('../../images/s.svg')?.default,
    alt: 'abcd',
    dark: true,
    primary: true,
    darkText: false
};


