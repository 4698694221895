import React,{useState} from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer'
import Sidebar from '../components/SIdebar'


const conditions = () => {
   

   
    return (
        <>
       
        
        <div>
            <h1>  Privacy Policy </h1>
            <p>
            The privacy policy (“Privacy Policy”) governs the use of the website of the Hemera Capital [www.hemera.capital](“Website”, “we”, “us”) and the services (“Services”) being provided on the Website.   
This Privacy Policy has been duly framed in accordance with the Information Technology Act, 2000 (“Act”) and the rules made thereunder to ensure maximum protection to the information provided by its users (“you”).We recognize the importance our customers place on the privacy and security of their business and personal information. 
We think it is important for you to be informed of the policies, procedures, and security measures that we have in place to safeguard your confidential information. With that in mind, we have developed this internet Privacy Policy to help you to understand the steps we take to protect your personal information when you utilize our online services.
Our goal is to protect your information in every way that we interact with you, whether on the Internet or otherwise.
By using the Website, you agree to abide by the terms and conditions pertaining to collection, retention and use of information set forth in this Privacy Policy. If you do not agree to the Privacy Policy, you may exit and cease to use the Website.
In addition to the considerations stipulated under this Privacy Policy your activities undertaken on the Website shall also be governed by the Procedural Guidelines, the Master Service Agreements (i.e. the Master Buyer Agreement, Master Financier Agreement, Master Supplier Agreement) signed by the respective parties with Mynd Solutions Private Limited through its business division Hemera (“Company”) and any other terms and conditions as published by the Company from time to time.
This Privacy Policy is incorporated in the Terms of Use of the Website and any other agreement, in which there is a specific clause incorporating the Privacy Policy in such agreement. In the event of conflict between the terms of such agreements and the Privacy Policy, the terms of such agreements will prevail and govern so long as they relate to matters specifically referenced herein and this Privacy Policy will apply with respect to all other matters.
1. Our role in protection of User Information: Mode of information Collection

1.1. We are committed to protecting the privacy of the information being provided by you on the Website.
1.2. We may collect personally identifiable information about a customer or former customer of the Website (“Customer Information”).
1.3. We may collect, retain and process the information provided by you through e-mail or filing of forms on the Website details of the organisation on behalf of whom you are acting (if applicable) and other information such as bank account or credit card or debit card or other payment instruments (“Personal Information”).
1.4. Further, we collect, store and process such other information including but not limited to information about your Transaction undertaken on the Website, communication made by you, information generated electronically when you visit the Website or use our services 
1.5. The “Service Providers” (third parties engaged by the Company who provide range of services like website hosting, digital signature verification .etc.) and the Website may collect general information on our Website visitors for security and statistical purposes. 
1.6. Such information may include but not limited to certain automatically generated information including your IP address, internet address of the referral site which brought you to visit our Website, date and time of visiting the Website, the name and version of your web browser, online activity, and duration of your online session. We and/ or third party service providers engaged by us may monitor and collect such information that will enable us to verify your credentials, maintain reasonable security practices, enable inclusion of better Services, fulfil your requests and enhance your user experience. 
1.7. We and/ or our Service Providers may also collect “Cookies” (piece of software code that the Website automatically sends to your browser when you access the Website) in order to collect some of the above mentioned information that enable us to provide you a better user experience. In some cases you must accept the Cookies in order to view our Website. 
2. Usage of Collected Information
2.1. The User Information provided by you may be used by us and provided to third party websites, affiliates, consultants, employees in order to manage, collect, store, process the User Information in order to improve the quality, design, functionality and content of the Website and to any governmental authority under applicable law.
2.2. The User Information provided by you shall be used to communicate with you by us through newsletters, updates, notifications and other forms of communication. The communication with you might be recorded but will be kept confidential otherwise than when asked to disclose to any governmental authority under applicable law.
2.3. The User Information shall be used for purposes only in relation to the Website and not for any other purposes. The User Information shall be retained till the termination of your Master Service Agreement or upto such other period as may be considered necessary by us for the purpose of operating the Website.
2.4. You shall have the right to view the information provided by you and also to update the information and keep us informed of any such change in case the information provided is deficient or inaccurate.
2.5. You undertake that the Personal Information and Other Information provided by you is true and accurate to the best of your knowledge. You agree and understand that we shall not be liable for the authenticity of the Personal Information and Other Information provided by you.
2.6. You shall provide us with all the information requested from you on the Website or through any other mode of communication. You are not legally obligated to provide us with all the information. However, complete functionality of the Website shall not be rendered possible if you fail to provide us with certain necessary information for the purpose of the Services of the Website. Without prejudice to the aforesaid, you shall have an option to, while availing the Services or otherwise, to withdraw your consent to allow us to use the Personal Information provided by you and intimate us of the same in writing.



3. Links to third party websites
3.1. The Website contains links to third party websites. We are not responsible for any content on such third party websites and we shall not be liable for any breach of privacy policy by such websites. For the avoidance of doubt, our Privacy Policy only governs the User Information collected, received, possessed, stored, dealt with or handled for the purposes of Services on our Website.
4. Disclosure of User Information
4.1. Any Personal Information provided by you shall be made available to only our Service Providers, third parties, employees, that are connected with or in relation to the Services of the Website. Such third parties shall not disclose the User Information disclosed to it further other than under such circumstances where disclosure is permissible by us under the terms and conditions of the Privacy policy.
4.2. Other than as set out above, we shall not disclose the User Information to any third party without your written consent basis the Master Service Agreement signed with you, provided that such information may be disclosed to governmental agencies or bodies as required under applicable law 
5. Notification and Updates sent by the Website
5.1. We send an email notification to individuals once they sign up to avail services of Hemera Capital Website as a Buyer/Supplier/Financier. We will send security related e-mail notifications once you sign up for “notify me” updates on your account(s). Further, we may send e-mail notifications whenever you change your password, security question or designated e-mail address.
6. Security Practices
6.1. The User Information shall be governed by and protected by us according to the security practices and procedures mandated under the Act and more particularly described under the Information Technology (Reasonable Security Practices & Procedures and Sensitive Personal Data or Information) Rules, 2011 and Information Technology (Intermediary Guidelines) Rules, 2011.
6.2. The Company (Hemera) and our Service Providers have developed stringent policies and procedure to safeguard the User Information.
6.3. The Website and our Service Providers maintain strong physical, electronic and procedural controls to protect against unauthorized access to customer information. Our computer systems are protected in the following ways:
6.3.1. Computer anti-virus protection detects and prevents viruses from entering our website, email, and computer network systems.
6.3.2. Firewalls and intrusion prevention systems block unauthorized access by individuals or networks.
6.3.3. We use encryption technology, such as Secure Socket Layer (SSL), to protect the transmission of your confidential information. Whenever you login to website or schedule an online transaction through our system, the communication is encrypted. Encryption scrambles the transferred data which ensures that it cannot be read by any unauthorized parties.
7. Beware of Phishing Attempts and Interest Scams
7.1. While email is convenient and has a good business use, it can also be misused by criminals for scams and various other fraudulent purposes. "Phishing emails" are frequently used by criminals to entice the recipient to visit a fraudulent website where they try to convince the recipient to provide personal & confidential information. Some of these fraudulent websites may also be virus laden and can be used to download mal-ware to your computer. Fraudulent websites often look identical to a legitimate site, so it's important to look very closely at the website address. Below we have listed a few tips to help protect your personal information on the Internet:
7.1.1. Always be wary of links in emails, especially any links in emails purporting to be from this Exchange.
7.1.2. Bookmark financial websites and use these bookmarks every time you visit the website.
7.1.3. Whenever you enter personal information like your access ID or passcode, always look for the lock symbol, or https: in the address bar. Always click on the lock symbol and review the certificate details.
7.1.4. Update your Internet browser. Most browsers now offer free anti-phishing tool bars that can help alert you of fraudulent websites.
7.1.5. Make sure that your computer always has up-to-date versions of both anti-spyware and anti-virus software.
7.1.6. If you receive an e-mail that you think could be a scam, delete it immediately or contact the helpline numbers of the Website. 7.1.7. If you have any questions about the legitimacy of an email, especially an email from this Website, you can also contact us at the numbers or email id provided under “contact us” section of the website
8. Alteration of the Privacy Policy
8.1. The Privacy Policy may be amended, modified or refined from time to time at our sole discretion and the updated Privacy Policy shall be published on the Website and no separate communication shall be made in respect of the same. 
8.2 It shall be your responsibility to keep yourself updated with changes to the Privacy Policy by regularly checking the Website for updates. Usage of the Website’s Services pursuant to a change in its Privacy Policy shall be deemed to be acquiescence of the changed Privacy Policy on your behalf.
9. Disclaimer of liability for User Information Security
9.1. We take appropriate measures as envisaged under Clause 6 of the Privacy Policy for the protection of the User Information. All the employees, consultants, screening committee members, mentors, and officers shall always be kept updated of the security and privacy protection procedures or methods. (We take appropriate disciplinary measures to enforce employee privacy and confidential responsibilities. We have established training programs to educate our employees about the importance of customer privacy and to ensure compliance with our policy requirements.)
9.2. Notwithstanding the aforesaid, despite our efforts to maintain privacy and confidentiality of the User Information, we may not be able to protect or prevent the unauthorised access or use, software failure or fault, or other factors that may compromise the security of the User Information.
9.3. You agree and understand that Internet is not a secure source and therefore, we cannot guarantee the protection of such User Information.
10. Grievance Redressal Mechanism
10.1. In order to address any of your grievances or discrepancies of information displayed on the Website, the Website shall designate a grievance redressal officer (“Grievance Officer”). [•] shall be the Grievance Officer of the Website.
10.2. The Grievance Officer shall redress all the grievances expeditiously but within 1(one) month from the date of receipt of the grievance as provided under the Act.


            </p>
        </div>
       


      

        <Footer />

        </>
    )
}




    export default conditions;