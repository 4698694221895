import React,{useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { Input } from '@material-ui/core';
import AddressForm from './AddressForm';
import { DropzoneDialog } from 'material-ui-dropzone';
import Button from '@material-ui/core/Button';
import { useFormikContext } from 'formik';
import Kyc from './Kyc';






const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: 265
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

export default function PaymentForm() {
 
  const { values: formValues } = useFormikContext();

  const classes = useStyles();
  return(
    <React.Fragment>
    <Kyc formValues={formValues} />


    </React.Fragment>

  )
}