import React from 'react'
import { ServicesWrapper,ServicesCard,ServicesContainer,ServicesH1,ServicesH2,ServicesIcon,ServicesP,ServicesTop} from './AdvantagesElements'
import t1 from '../../images/t1.png'
import fund1 from '../../images/fund1.png'
import cycle from '../../images/cycle.png'

import paper from '../../images/paper.png'
import process from '../../images/process.png'

import data from '../../images/data .png'
import online from '../../images/online.png'
import discount from '../../images/discount.png'
import platform from '../../images/platform.png'


const Advantages = ({lightBg,greenBg,id,imgStart,topLine,lightText,headline,darkText,description,buttonLabel,img,alt,primary,dark,dark2}) => {
    return (
    <>
        
            

        <ServicesContainer id='advantages' >
            <ServicesH1> Advantages </ServicesH1>
            {/* <ServicesTop> "We solve all your needs"</ServicesTop> */}
            <ServicesWrapper>
                <ServicesCard>
                    <ServicesIcon src={fund1}/>
                    <ServicesH2>Easy Access to Fund</ServicesH2>
                   
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={cycle}/>
                    <ServicesH2>Shorter Cash Cycles</ServicesH2>
                   
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={paper}/>
                    <ServicesH2>No paper Work</ServicesH2>
                    
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={process}/>
                    <ServicesH2>Standardized Practices</ServicesH2>
                    
                </ServicesCard>
            </ServicesWrapper>
            <div></div>
            <ServicesWrapper>
            <ServicesCard>
                    <ServicesIcon src={platform}/>
                    <ServicesH2>Single Platform for sellers, Buyers and Financers</ServicesH2>
                   
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={online}/>
                    <ServicesH2>Transact Online</ServicesH2>
                   
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={discount}/>
                    <ServicesH2>Competitive Discount Rates</ServicesH2>
                   
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={data }/>
                    <ServicesH2>Seamless Data Flow</ServicesH2>
                   
                </ServicesCard>
            



            </ServicesWrapper>
        </ServicesContainer>
       
        </>
    )
}


export default Advantages;
