import React,{useState} from 'react'
 import Video from '../../video/video.mp4'
 import { Button } from '../ButtonElement'
 import {HeroContainer,HeroBg,VideoBg,HeroContent,HeroH1,HeroBtnWrapper,HeroP,ArrowForward,ArrowRight } from './HeroElements'
 
 const HeroSection = () => {
     const [hover,setHover] = useState(false)
     const onHover = () => {
         setHover(!hover)
     }
     const tagline=`Structured Finance For Your Business`
     return (
         <HeroContainer>
             <HeroBg>
                 <VideoBg autoPlay loop muted src={Video} type='video/mp4'/>
                  
             </HeroBg>
             <HeroContent>
                 <HeroH1>Business Finance Made Easy</HeroH1>
                  <HeroP> {tagline} </HeroP> 
                 <HeroBtnWrapper>
                     {/* <Button to = 'signup' onMouseEnter={onHover} onMouseLeave={onHover}
                     primary="true" 
                     dark="true">
                         Get Started {hover? <ArrowForward /> : <ArrowRight />}
                     </Button> */}
                 </HeroBtnWrapper>
             </HeroContent>
         </HeroContainer>
     )
 }
 
 export default HeroSection
 
