import React from 'react'
import { ServicesWrapper,ServicesCard,ServicesContainer,ServicesH1,ServicesH2,ServicesIcon,ServicesP,ServicesTop} from './ReviewElements'
import t1 from '../../images/t1.png'
import rman1 from '../../images/rman1.png'




const Reviews = ({lightBg,greenBg,id,imgStart,topLine,lightText,headline,darkText,description,buttonLabel,img,alt,primary,dark,dark2}) => {
    return (
    <>
        
            

        <ServicesContainer id='testimonial' >
            <ServicesH1> Testimonials</ServicesH1>
            <ServicesTop> "What Our Clients  Say"</ServicesTop>
            <ServicesWrapper>
                <ServicesCard>
                    <ServicesIcon src={t1}/>
                    <ServicesH2>John Doe</ServicesH2>
                    <ServicesP> We are highly impressed with the online trade receivables digital platform from  Hemera Capital</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={t1}/>
                    <ServicesH2>John Appleseed</ServicesH2>
                    <ServicesP> We are highly impressed with the online trade receivables digital platform from  Hemera Capital</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={t1}/>
                    <ServicesH2>Jeff Doe</ServicesH2>
                    <ServicesP> We are highly impressed with the online trade receivables digital platform from  Hemera Capital</ServicesP>
                </ServicesCard>
            </ServicesWrapper>
            <ServicesWrapper>
            



            </ServicesWrapper>
        </ServicesContainer>
       
        </>
    )
}


export default Reviews;
